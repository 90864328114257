<template>
    <modal ref="modalAgregarCarpeta" :titulo="titleText" :adicional="buttonText" @adicional="crearCarpeta">
        <ValidationObserver ref="validator">
            <div class="row justify-content-center m-3 f-15">
                <div class="col-12 my-2">
                    <ValidationProvider name="nombre de la carpeta" rules="required" v-slot="{errors}">
                        <p class="input-label-top">Nombre de la carpeta</p>
                        <el-input v-model="model.nombre" class="w-100" size="small" />
                        <vee-error :text="errors[0]"/>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
export default {
    data(){
        return {
            id: null,
            model: {    
                nombre: '',
            }
        }
    },
    computed:{
        titleText(){
            return `${this.id ? 'Editar' : 'Crear'} carpeta`
        },
        buttonText(){
            return this.id ? 'Guardar' : 'Crear'
        }
    },
    methods: {
        toggle(){
            this.reset()
            this.$refs.modalAgregarCarpeta.toggle()
        },
        edit(data){
            this.id = data.id
            this.model.nombre = data.nombre
            this.$refs.modalAgregarCarpeta.toggle()
        },
        reset(){
            this.id = null
            this.model.nombre = ''
            this.$refs.validator.reset()
        },
        async crearCarpeta(){
            const valid = await this.$refs.validator.validate()
            if(!valid) return

            this.id 
                ? this.$emit('update', { id_folder: this.id, folder: this.model })
                : this.$emit('save', this.model)
        }
    }
}
</script>